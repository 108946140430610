<template>
  <div>
    <div class="message">
      The Lincoln Effortless Experience has moved.
      Please click below to navigate to Lincoln University.
    </div>
    <div class="action">
      <button
        type="button"
        class="action-button"
        @click="onActionClicked">
          Lincoln University
      </button>
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: 'LandingView',
  methods: {
    onActionClicked() {
      window.location.href = 'https://lincolnuniversity.com';
    },
  },
};
</script>

<style scoped>
  .message {
    text-align: center;
    font-size: 1.8rem;
    color: #324148;
    margin-top: 200px;
    margin-left:5%;
    margin-right:5%;
  }

  .action {
    margin-top:50px;
    width: 150px;
    margin-left:auto;
    margin-right:auto;
  }

  .action-button {
    width: 150px;
    height: 40px;
    background-color: #324148;
    color: white;
    font-weight: bold;
  }
</style>
