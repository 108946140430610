var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "message" }, [
      _vm._v(
        "\n    The Lincoln Effortless Experience has moved.\n    Please click below to navigate to Lincoln University.\n  "
      ),
    ]),
    _c("div", { staticClass: "action" }, [
      _c(
        "button",
        {
          staticClass: "action-button",
          attrs: { type: "button" },
          on: { click: _vm.onActionClicked },
        },
        [_vm._v("\n        Lincoln University\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }